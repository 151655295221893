import AddCostMainScreen from './AddCostMainScreen/AddCostMainScreen.vue';
import AddCostQuotationScreen from './AddCostQuotationScreen/AddCostQuotationScreen.vue';
import AddCostDetailService from '../../../services/API/add_cost_details.service';
import EstimateParticularService from '../../../services/API/estimate_particular.service';
import TransferService from '@/services/API/transfer.service';
import DocumentService from '@/services/API/document.service';

// import socketClient from '../../../services/SOCKET';
import { mapState } from 'vuex';

export default {
	name: 'AddCostScreen',
	components: {
		AddCostMainScreen,
		AddCostQuotationScreen,
	},
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			quotationError: false,
			// projectInfo: {
			// 	projectId: this.$store.state.projectId,
			// 	projectName: '',
			// 	companyName: '',
			// },
			isQuotationShow: undefined,
			isShowContent: false,
			estimateParticularId: undefined,
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			pdfData: {},
			pdfFileKey: '',
			defaultFilePath: 'default-template.jpeg',
			expiration: '86400',
			pdfImageUrl: '',
			pdfFileUrl: '',
			quoteId: '',
			addCostDetails: [],
			addCostDetail: {},
			addCostStatus: undefined,
		};
	},
	computed: {
		// Get userId from store
		...mapState([
			'projectId',
			'userId',
			'clientInfo',
			'managementMasterInfo',
			'projectInfo',
		]),
	},

	watch: {
		// Calculate amount when consumption_tax_rate and addcostDetails existed
		managementMasterInfo(newVal) {
			if (newVal['consumption_tax_rate'] && this.addCostDetails.length > 0) {
				this.computeAddCostDetailsMoneyAmount(
					newVal['consumption_tax_rate'],
					this.addCostDetails
				);
			}
		},
	},

	methods: {
		onPreviewButtonClick(data) {
			this.isQuotationShow = data;
		},
		onEditButtonClick(data) {
			this.isQuotationShow = data;
		},

		onOpenCoCreation(coCreateScreenIdx) {
			this.$emit('open-co-create', coCreateScreenIdx);
		},

		onChangeAddCostStatus(status) {
			this.addCostStatus = status;
		},

		/**
		 * Calculate subtotal of all addCostDetail records
		 * @param {Array} addCostDetails AddCostDetail array
		 * @returns addCostDetailsSubtotal
		 */
		addCostDetailsSubtotal(addCostDetails) {
			if (addCostDetails && addCostDetails.length > 0) {
				return addCostDetails.reduce((acc, addCostDetail) => {
					return acc + Math.trunc(addCostDetail['quantity'] * addCostDetail['unit_price']);
				}, 0);
			}
		},

		/**
		 * Calculate Addcost Details money amount
		 * @param {Integer} consumptionTaxRate ManagementMaster consumption tax rate
		 * @param {Array } addCostDetails AddCostDetail array
		 */
		computeAddCostDetailsMoneyAmount(consumptionTaxRate, addCostDetails) {
			if (consumptionTaxRate && addCostDetails.length > 0) {
				let addCostDetailsSubtotal = this.addCostDetailsSubtotal(addCostDetails);

				let addCostDetailsConsumptionTax =
					addCostDetailsSubtotal * consumptionTaxRate / 100;
				let subtotal =
					this.originEstimateData.estimateParticular['subtotal'] +
					addCostDetailsSubtotal;
				let consumptionTax =
					Math.trunc(this.originEstimateData.estimateParticular['consumption_tax'] +
						addCostDetailsConsumptionTax);
				let holdingTax = 0
				if (this.projectInfo.with_holding_tax == 1) {
					if (subtotal < 1000000) {
						holdingTax = (subtotal * 10.21) / 100;
					} else {
						holdingTax = ((subtotal - 1000000) * 20.42) / 100 + 102100;
					}
				}
				holdingTax = Math.trunc(holdingTax);
				this.originEstimateData.estimateParticular['holding_tax'] = holdingTax;
				this.originEstimateData.estimateParticular['subtotal'] = subtotal;
				this.originEstimateData.estimateParticular['consumption_tax'] = consumptionTax;
				this.originEstimateData.estimateParticular['total'] = subtotal + consumptionTax
					+ holdingTax;
			}
		},

		async onUploadedFile(key) {
			this.pdfFileKey = key.pdfFileKey;
			let response = await DocumentService.get(this.projectId);
			let documents = response.data;
			documents.order_confirm.push(key.pdfFileKey);
			let params = {
				pdf_add_cost_file_key: this.pdfFileKey,
				// order_confirm_file_key: documents.order_confirm,
			};
			await this._updateEstimateParticular(this.estimateParticularId, params);
			await this._getPdfFileUrl(this.pdfFileKey);
		},
		async onSaveButtonClick(data) {
			this.isQuotationShow = data.isShowQuotation;
			await this._getEstimateMetaData(this.projectId);
			// this.pdfData = { ...this.originEstimateData };
			// Start render PDF file
			this.$refs.addCostMainScreen._renderTemplateString();
			// await this._getAddCostDetails(this.estimateParticularId);
			this.$refs.addCostMainScreen._getAddCostDetails(this.estimateParticularId);
		},

		onGetConfirmInfo(data) {
			// console.log(data);
			this.$emit('on-send-confirm-info', data);
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateMetaData(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);
				if (response && response.status === 200) {
					let data = response.data;
					this.estimateParticularId = data['estimate_particular']['id'];
					this.addCostDetails = await this._getAddCostDetails(
						this.estimateParticularId
					);
					let addCostDetail = this.addCostDetails[this.addCostDetails.length - 1];
					this.addCostDetail = addCostDetail;
					this.addCostStatus = addCostDetail ? addCostDetail['status'] : undefined;
					if (this.addCostDetails.length === 0) {
						this.pdfFileKey = data['estimate_particular']['pdf_file_key'];
					} else {
						this.pdfFileKey = data['estimate_particular']['pdf_add_cost_file_key'];
					}

					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});
					for (let i = 0; i < estimateDetails.length; i++) {
						estimateDetails[i]['disabled'] = true;
					}
					console.log(this.projectInfo);
					let projectName = {
						project_name: this.projectInfo['project_name'],
					};
					this.originEstimateData.estimateParticular = {
						...data['estimate_particular'],
						...projectName,
					};

					if (addCostDetail) {
						if (addCostDetail['status'] === 'onCreateNew') {
							this.isQuotationShow = false;
						} else {
							this.isQuotationShow = true;
						}
					}
					this.computeAddCostDetailsMoneyAmount(
						this.managementMasterInfo['consumption_tax_rate'],
						this.addCostDetails
					);
					this.originEstimateData.estimateDetails = estimateDetails.concat(
						this.addCostDetails
					);
					console.log(this.originEstimateData);
					this.pdfData = { ...this.originEstimateData };
					this.projectInfo.companyName = data['estimate_particular']['company_name'];
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				console.log('ADD COST DETAIL asdhjaskjdhaksjh', addCostDetails);
				return addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: green');
				let url = response.data.link;
				console.log({ RESPONSEurl: url });
				return url;
				// console.log({ pdfImageUrl: this.pdfImageUrl });
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			await this._getEstimateMetaData(this.projectId);
			await this._getPdfFileUrl(this.pdfFileKey);
		},

		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				let pdfFileUrl = await this._getPreviewPDFUrl(pdfFileKey, this.expiration);
				this.pdfFileUrl = pdfFileUrl;
			}
		},

		async _updateEstimateParticular(estimateParticularId, body) {
			console.log('updating add_cost_pdf', body);
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: body,
			});
			if (response && response.status === 200) {
				console.log('Update Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},

		onGetQuotationError() {
			this.quotationError = !this.quotationError;
		},
		/**
		 * update projectStatus in projectInfo
		 */
		onUpdateProjectStatus(status) {
			this.$emit('on-update-project-status', status);
		},
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this._getEstimateData();
		this.isShowContent = true;

		this.$store.commit('setIsAppProcessing', false);
	},
};
