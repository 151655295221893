import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '../../../../components/NotificationModal/NotificationModal.vue';
import socketClient from '../../../../services/SOCKET';
import HxPdfExporter from '../../../../components/HxPdfExporter/HxPdfExporter.vue';
import EstimateParticularService from '../../../../services/API/estimate_particular.service';
import Modal from '../../../../components/Modal/Modal.vue';
import Badge from '../../../../components/Badge/StatusBadge.vue';
import AddCostDetailService from '../../../../services/API/add_cost_details.service';
import ReProposalGeneralService from '../../../../services/API/reproposal_general.service';
import TransferService from '@/services/API/transfer.service';
import DocumentService from '@/services/API/document.service';

import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';
import CommonConst from '@/constants/CommonConst';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

const REPROPOSAL_SCREEN_ID = 7;
const ADD_COST_SCREEN_ID = 6;
const SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? '「 再提案設定 」 をクライアントに\n送信します。'
	: '「 再提案設定 」 をクライアントに送信します';

export default {
	name: 'AddCostMainScreen',
	mixins: [updateProjectStatusMixin],
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,
		HxPdfExporter,
		BottomButton,
		Modal,
		Badge,
	},
	props: {
		pdfData: {
			type: Object,
		},
		pdfImageUrl: {
			type: String,
		},
		pdfFileUrl: {
			type: String,
		},
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			screenIcon: 'addcost-icon',
			screenName: '再提案設定',
			selectiveContents: SELECTIVE_NOTI_CONTENTS,
			companyAddress: '',
			total: '',
			issueDate: '',
			postNumber: '',
			showEditButton: false,
			showBottomButton: false,
			showDownloadButton: false,
			defaultFileName: '',
			pdf: '',
			pdfFileData: '',
			selectiveModalId: 'estimate-selective-modal',
			plainModalId: 'estimate-plain-modal',
			middleContent: ' 様 ／ ',
			isRetryReasonShow: false,
			isSearchIconShow: false,
			buttonName: '送 信',
			buttonType: 'single-btn',
			showStatusBadge: false,
			badgeContent: '',
			requiredReason: '',
			addCostStatus: '',
			lastAddCostItem: '',
			addcostRedeliveryDate: '',
			addcostReproposalDate: '',
			addcostDetailRemark: '',
			reproposalDirectLink: 'reproposal',
			mailContent: '',

			pdfPreviewer: {},
			mailTitle: '',
			subject: '',
			isSendCreator: false,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}addcost`
						: `${this.projectInfo['process_client_url']}addcost`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}addcost`
					: `${this.projectInfo['process_client_url']}addcost`,
			});
		},

		// eslint-disable-line no-mixed-spaces-and-tabs
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	watch: {
		projectInfo: {
			handler: function (projectInfo, clientInfo) {
				this._getDefaultFileName(projectInfo, clientInfo);
			},
			deep: true,
		},

		pdfData: {
			handler: function (pdfData) {
				this._checkAddCostStatus(this.addCostStatus);
				this.projectName = pdfData.estimateParticular['project_name'];
			},
			deep: true,
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		addCostStatus(newStatus) {
			if (
				newStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
				newStatus === CommonConst.SCREEN_STATUS.DECLINE
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.ADDCOST]);
			} else {
				const redDotIndexs = this.redDotIndexs.filter(
					(screenId) => screenId !== CommonConst.SCREEN_ID.ADDCOST
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},
	},
	methods: {
		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			if (!this.pdfFileUrl) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			this._getDefaultFileName(this.projectInfo, this.clientInfo);
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(this.pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = this.$refs.downloadTag;
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = this.defaultFileName;
				aTag.click();
			} catch (error) {
				console.log(error);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onDirectButtonClick() {
			this.$store.commit('setIsAppProcessing', true);

			let addCostDetail = {
				status: CommonConst.SCREEN_STATUS.FINISH,
			};
			await this.updateProjectStatus(this.projectId, ADD_COST_SCREEN_ID + 1);
			await this._updateAddcostDetail(this.lastAddCostItem['id'], addCostDetail);

			this.addCostStatus = CommonConst.SCREEN_STATUS.FINISH;

			if (await this._checkExistReProposalGeneral(this.projectId)) {
				await this._updateReProposalGeneral(this.projectId, {
					status: '',
					hours: null,
					minutes: null,
					countdown_flag: 0,
				});
			}

			let confirmInfo = {
				screenId: ADD_COST_SCREEN_ID,
				confirmInfo: true,
			};

			this.$refs.hxPdfExporterOrderConfirm._renderTemplateString();

			this.$emit('on-send-confirm-info', confirmInfo);
			socketClient.send('addcost_creator_direct', {
				userId: this.userId,
				projectId: this.projectId,
				content: this.reproposalDirectLink,
			});
			// send mail case 49
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「再提案」スタート`;
			this.mailContent = `「再提案」を\nスタートします。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「再提案」スタート`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「再提案」スタート`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「再提案」スタート`;
			this.mailContent = `「再提案」を\nスタートします。`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);
			this.$emit('on-update-project-status', REPROPOSAL_SCREEN_ID);

			this.$store.commit('setIsAppProcessing', false);

			// When change screen from AddCost to another, turn off CoCreate Sharing Mode
			this.$store.commit('setIsShareCoCreate', false);
			this.$router.push(this.reproposalDirectLink);
		},

		onEditButtonClick() {
			this.$emit('on-edit-btn-click', false);
		},

		onBottomButtonClick() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		async onRenderTemplate() {
			let pdfFileData = await this.$refs.hxPdfExporter.getRenderedPDFTemplate(
				'addCost.pdf'
			);
			// this.pdfFileData = pdfFileData
			let pdfFileParams = {
				content: pdfFileData,
				project_id: this.projectId,
				file_name: `template-add-cost.pdf`,
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			this.$emit('on-transfer-uploaded-file', { pdfFileKey });

			this.$store.commit('setIsAppProcessing', false);
		},

		async onRenderOrderConfirm() {
			let pdfFileData =
				await this.$refs.hxPdfExporterOrderConfirm.getRenderedPDFTemplate(
					'addCost.pdf'
				);
			let response = await DocumentService.get(this.projectId);
			let documents = response.data;
			let pdfFileParams = {
				content: pdfFileData,
				project_id: this.projectId,
				file_name: `order-confirm-${documents.order_confirm.length}.pdf`,
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			documents.order_confirm.push(pdfFileKey);
			await this._updateEstimateParticular(this.estimateParticularId, {
				order_confirm_file_key: documents.order_confirm,
			});
		},
		async _updateEstimateParticular(estimateParticularId, body) {
			console.log('updating add_cost_pdf', body);
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: body,
			});
			if (response && response.status === 200) {
				console.log('Update Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},
		_renderTemplateString() {
			this.$refs.hxPdfExporter._renderTemplateString();
		},

		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			this.showStatusBadge = true;
			this.badgeContent = 'クライアント承認待ち';
			this.showBottomButton = false;
			this.showEditButton = false;

			let addCostDetail = {
				status: CommonConst.SCREEN_STATUS.WAITING_CONFIRM,
			};
			await this._updateAddcostDetail(this.lastAddCostItem['id'], addCostDetail);
			this.addCostStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;

			socketClient.send('send_new_addcost_quotation', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});
			// send mail to creator case 44
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「再提案の詳細」の承認依頼を送りました`;
			this.mailContent = `「再提案の詳細」の\n承認依頼を送りました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 「再提案の詳細」の承認依頼を送りました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「再提案の詳細」の承認依頼が届きました`;
			this.mailContent = `「再提案の詳細」の\n承認依頼が届きました。`;
			this.isSendCreator = false;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「再提案の詳細」の承認依頼が届きました`;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		onPDFClick() {
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
		},

		_getDefaultFileName(projectInfo, clientInfo) {
			let nowDate = new Date();
			let downloadDateTime = moment(nowDate).format('YYYYMMDDHHmmss');
			this.defaultFileName =
				clientInfo['client_name'] +
				'様_' +
				projectInfo['project_name'] +
				'_見積書_' +
				downloadDateTime +
				'.pdf';
		},

		/**
		 * Get quoteID from estimate_particular and estimate_details
		 * @param {String} projectId
		 */
		async _getEstimateParticularId(projectId) {
			let response = await EstimateParticularService.get(projectId);
			if (response && response.status === 200) {
				let estimateParticularId = response.data['estimate_particular']['id'];
				return estimateParticularId;
			} else {
				console.log('Get estimate particular failed');
			}
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				this.lastAddCostItem = addCostDetails[addCostDetails.length - 1];
				this.addCostStatus = addCostDetails[addCostDetails.length - 1]['status'];
				this.addcostRedeliveryDate = moment(
					this.lastAddCostItem['redelivery_date']
				).format('YYYY/MM/DD');
				if (this.addCostStatus === CommonConst.SCREEN_STATUS.ACCEPT) {
					let updateSchedule = {
						...this.schedule,
						delivery_date: this.addcostRedeliveryDate,
					};
					this.$store.commit('setSchedule', updateSchedule);
				}
				this.addcostReproposalDate = moment(
					this.lastAddCostItem['reproposal_date']
				).format('YYYY/MM/DD HH:mm');
				this.addcostDetailRemark = this.lastAddCostItem['remark'];
				this.requiredReason = this.lastAddCostItem['reason'];
				this._checkAddCostStatus(this.addCostStatus);
				this.showDownloadButton = true;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		/**
		 * Update bulk addCostDetail records
		 * @param {Array} addCostDetail Array of addCostDetail Object
		 */
		async _updateAddcostDetail(id, addCostDetail) {
			try {
				let response = await AddCostDetailService.update(id, addCostDetail);
				if (response && response.status === 200) {
					console.log('Update Addcost detail successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update ReProposal General
		 * @param {Integer} projectId Project ID
		 * @param {Object} content updated content
		 */
		async _updateReProposalGeneral(projectId, content) {
			try {
				let response = await ReProposalGeneralService.put(projectId, content);
				if (!response || response.status !== 200) {
					throw 'Update Proposal General failed!';
				}

				console.log('%c Update Proposal General successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		async _checkExistReProposalGeneral(projectId) {
			try {
				let response = await ReProposalGeneralService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Proposal General failed!';
				}

				console.log('%c Get Proposal General successfully!', 'color: green');

				if (response.data.id) return true;
				else return false;
			} catch (error) {
				console.log(error);
			}
		},

		_checkAddCostStatus(status) {
			console.log('STATUS', status);
			if (status === CommonConst.SCREEN_STATUS.SAVE) {
				this.showStatusBadge = false;
				this.showBottomButton = true;
				this.showEditButton = true;
			} else if (status === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showStatusBadge = true;
				this.badgeContent = 'クライアント承認待ち';
				this.showBottomButton = false;
				this.showEditButton = false;
			} else if (status === CommonConst.SCREEN_STATUS.DECLINE) {
				this.showBottomButton = false;
				this.showEditButton = true;
				this.isRetryReasonShow = true;
			} else if (status === CommonConst.SCREEN_STATUS.ACCEPT) {
				this.showStatusBadge = false;
				this.showBottomButton = true;
				this.buttonType = 'direct-screen-btn';
				this.buttonName = '再提案に進む';
			}
		},
	},

	created() {
		socketClient.listen('creator_received_addcost_confirm', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getAddCostDetails(this.estimateParticularId);

				this.$store.commit('setIsAppProcessing', false);
			}
		});

		socketClient.listen('addcost_received_required', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			this.showStatusBadge = false;
			this.isRetryReasonShow = true;
			this.requiredReason = data.reason;
			await this._getAddCostDetails(this.estimateParticularId);

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		this.estimateParticularId = await this._getEstimateParticularId(this.projectId);
		await this._getAddCostDetails(this.estimateParticularId);

		this.$store.commit('setIsAppProcessing', false);
	},

	beforeDestroy() {
		socketClient.removeAllListeners('creator_received_addcost_confirm');
		socketClient.removeAllListeners('addcost_received_required');
	},
};
