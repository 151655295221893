import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import FormInput from '@/components/FormInput/FormInput.vue';
import DetailTable from '@/components/DetailTable/DetailTable.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import DateTimePicker from '@/components/DateTimePicker/DateTimePicker.vue';
import FunctionalCalendar from '@/components/DateRange/FunctionalCalendar.vue';

import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import AddCostDetailService from '../../../../services/API/add_cost_details.service';

import { mapState } from 'vuex';
import moment from 'moment';

export default {
	name: 'AddCostQuotationScreen',
	components: {
		ScreenHeader,
		ProjectDeadline,
		FormInput,
		DetailTable,
		SaveButtonArea,
		NotificationModal,
		DateTimePicker,
		FunctionalCalendar,
	},
	props: {
		/**
		 * @binding estimate Props Data
		 * @value estimateParticular {object}: estimate particular data
		 * @value estimateDetails {array}: array of estimate detail objects
		 */
		estimatePropData: {
			type: Object,
		},
		propUserId: {
			type: Number,
		},
		projectId: {
			type: Number,
		},
		addCostDetail: {
			type: Object,
		},
	},
	data() {
		return {
			screenIcon: 'addcost-icon',
			middleContent: ' 様 ／ ',
			screenName: '再提案設定',
			projectDeadline: '2021 年 9 月 25日',
			// 詳細データ
			infoFormInput: {},
			// 明細データ
			// estimateDetails: [],
			originEstimateDetails: [],
			comparesionEstimateDetails: [],
			// 明細データからトータルになる金額のデータ
			estimateParticular: {},
			estimateParticularId: undefined,
			infoBillCosts: {},
			//TODO: Store userId, projectId as global variables
			remarkContent: '',
			deletedItems: [],
			formInputValid: true,
			dateSelected: undefined,
			redeliveryDate: {},
			reproposalDate: {},
			additionalTime: undefined,
			projectCost: undefined,
			additionalCostRemarkContent: '',
			minDate: moment().format('YYYY/MM/DD'),
			plainModalId: 'amount-error-modal-id',
			plainContents: '1億円以上の取引はできません',
		};
	},
	computed: {
		// Get userId from store
		...mapState([
			'userId',
			'projectInfo',
			'clientInfo',
			'managementMasterInfo',
			'schedule',
		]),
		totalAdditionCost() {
			if (this.additionalTime && this.projectCost)
				return Math.trunc(
					Number(this.additionalTime) * this.revertThousandNum(this.projectCost)
				);

			return 0;
		},
		isSaveButtonDisable() {
			// If all required input is filled, return false to enable Save Button
			if (
				!this.additionalTime ||
				!this.additionalCostRemarkContent ||
				!this.redeliveryDate ||
				!this.reproposalDate.dateSelected ||
				typeof this.reproposalDate.hourSelected !== 'number' ||
				typeof this.reproposalDate.minuteSelected !== 'number'
			)
				return true;
			// If all required input is not fill yet, return true to disable Save Button
			else return false;
		},
		dateTimePickerValue() {
			if (
				this.addCostDetail &&
				this.addCostDetail['reproposal_date'] &&
				this.addCostDetail['status'] !== 'onCreateNew'
			)
				return new Date(this.addCostDetail['reproposal_date']);
		},
	},
	watch: {
		/**
		 * Watch prop estimatePropData change
		 * @param {object} estimatePropData
		 */
		estimatePropData: {
			handler: function (estimatePropData) {
				console.log('%c Watch', 'color: red');
				let originEstimateDetails = [...estimatePropData.estimateDetails];

				this.originEstimateDetails = [...originEstimateDetails];
				this.comparesionEstimateDetails = [...estimatePropData.estimateDetails];
				this.estimateParticular = {
					...estimatePropData.estimateParticular,
				};
				this.remarkContent = estimatePropData.estimateParticular['comment'];

				if (estimatePropData.estimateParticular['id']) {
					this.estimateParticularId = estimatePropData.estimateParticular['id'];
				}
			},
			deep: true,
		},

		addCostDetail: {
			handler: function (addCostDetail) {
				if (addCostDetail && addCostDetail.status !== 'onCreateNew') {
					this.additionalTime = addCostDetail['quantity'];
					this.projectCost = addCostDetail['unit_price'];
					this.additionalCostRemarkContent = addCostDetail['remark'];

					let redeliveryDate = {
						selectedDate: moment(addCostDetail['redelivery_date']),
						dateRange: {
							start: '',
							end: '',
						},
					};
					this.redeliveryDate = redeliveryDate;

					let reproposalDate = moment(addCostDetail['reproposal_date']);
					let reproposalDateObj = {
						dateSelected: {
							selectedDate: reproposalDate.format('YYYY/MM/DD'),
						},
						hourSelected: parseInt(reproposalDate.format('HH')),
						minuteSelected: parseInt(reproposalDate.format('mm')),
					};
					this.reproposalDate = reproposalDateObj;
				}
			},
			deep: true,
		},

		// Allow up to 2 decimal places
		additionalTime(newAdditionalTime) {
			// Prevent string like "1."
			if (newAdditionalTime && newAdditionalTime[newAdditionalTime.length - 1] !== '.')
				this.additionalTime = this.$options.filters.allow2Decimal(
					Number(newAdditionalTime)
				);
		},

		'projectInfo.cost'(newProjectCost) {
			this.projectCost = newProjectCost;
		},

		projectCost(newProjectCost) {
			if (newProjectCost && typeof newProjectCost !== 'number') {
				// Convert Full-width number to Haft-width number
				const result = newProjectCost
					.replace(/\D/g, '')
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				this.projectCost = result;
				// Vue.nextTick(() => (this.projectCost = result));
			}
		},
	},

	filters: {
		allow2Decimal(value) {
			return value ? Math.round(value * 100) / 100 : null;
		},
		// /**
		//  * Format number to thousand
		//  * @param {Number} number
		//  * @returns number string with comma in thousand
		//  * example: "123,4567"
		//  */
		// formatThousandNum(number) {
		// 	if (isNaN(number)) return;

		// 	if (number < 10000) return number;

		// 	let numString = number.toString();
		// 	let indexOfDot = numString.indexOf('.');
		// 	let commaIdx = indexOfDot >= 0 ? indexOfDot - 4 : numString.length - 4;
		// 	return numString.slice(0, commaIdx) + ',' + numString.slice(commaIdx);
		// },
	},

	methods: {
		onClickInput() {
			console.log('ON INPUT CLICK');
		},

		onOpen() {
			this.$emit('on-open-co-create', 4);
		},

		onDateTimePickerChange(data) {
			this.reproposalDate = data;
			console.log(data);
		},

		/**
		 * Convert Date
		 * @param {DateTime} date
		 * @returns Date string in format 2021 年 10 月 05 月まで
		 */
		_filterDate(date) {
			let momentDate = moment(date);
			let year = momentDate.format('YYYY');
			let month = momentDate.format('MM');
			let day = momentDate.format('DD');
			return `${year} 年 ${month} 月 ${day} 日`;
		},

		getDeletedItems(deletedItems) {
			this.deletedItems = deletedItems;
			//return deletedItems
		},

		async onSaveButtonClick() {
			this.$store.commit('setIsAppProcessing', true);

			// Get remark content data
			let addCostDetail = {
				item_name: this.redeliveryDate ? '再提案' : null,
				quantity: Number(this.additionalTime),
				quote_id: this.estimateParticularId,
				tax_classification: 1,
				unit: '時間',
				unit_price: this.revertThousandNum(this.projectCost),
				amount: this._caculatedAmount(
					Number(this.additionalTime),
					this.revertThousandNum(this.projectCost),
					Number(this.managementMasterInfo['consumption_tax_rate'])
				),
				reproposal_date: moment(
					this.reproposalDate.dateSelected.selectedDate.replaceAll('-', '/') +
						' ' +
						this.reproposalDate.hourSelected +
						':' +
						this.reproposalDate.minuteSelected
				),
				redelivery_date: moment(this.redeliveryDate.selectedDate),
				remark: this.additionalCostRemarkContent,
				status: 'onSave',
				update_user: this.userId,
			};

			if (!this.addCostDetail || this.addCostDetail['status'] === 'onCreateNew') {
				await this._createAddcostDetail(addCostDetail);
			} else {
				await this._updateAddcostDetail(this.addCostDetail['id'], addCostDetail);
			}
			let sendData = {
				isShowQuotation: true,
			};
			this.$emit('on-save-btn-click', sendData);
		},

		_caculatedAmount(time, cost, tax) {
			const taxRatio = tax / 100;
			const ratioAfterTax = 1 + taxRatio;
			const amountBeforeTax = time * cost;
			const amount = Math.floor(amountBeforeTax * ratioAfterTax);
			return amount;
		},

		revertThousandNum(stringNum) {
			return Number(stringNum.toString().replace(',', ''));
		},

		/**
		 * Create Addcost Detail records
		 * @param {Array} addCostDetail Array of Addcost Detail  Object
		 */
		async _createAddcostDetail(addCostDetail) {
			try {
				let bodyContent = { ...addCostDetail };
				let response = await AddCostDetailService.post(bodyContent);
				if (response && response.status === 200) {
					console.log('Create Addcost detail successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update bulk addCostDetail records
		 * @param {Array} addCostDetail Array of addCostDetail Object
		 */
		async _updateAddcostDetail(id, addCostDetail) {
			try {
				let response = await AddCostDetailService.update(id, addCostDetail);
				if (response && response.status === 200) {
					console.log('Update Addcost detail successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(error);
			}
		},
	},

	mounted() {
		if (this.projectInfo['cost']) {
			this.projectCost = this.projectInfo['cost'];
		}
	},
};
